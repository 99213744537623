.company_expired {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 80vh;

  &:before {
    content: "";
    position: absolute;
    top: -48px;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--geist-background);
    opacity: 0.75;
    z-index: 99;
  }
}

.inner {
  position: relative;
  z-index: 100;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  flex: 1 1;
  align-self: center;
  padding: var(--geist-gap);
  flex-direction: column;
  max-width: 600px;
  position: relative;

  h1 {
    text-align: center;
    margin-bottom: 2.5rem;
  }
}
