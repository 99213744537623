.header_navigation {
  --menu-logo-size: 20px;
  --menu-gap: 9px;
  --menu-gap-offset: calc(var(--menu-gap) * -1);
  box-shadow: inset 0 -1px var(--accents-2);

  background: var(--geist-background);
  z-index: 80;

  &.sticky {
    position: sticky;
    top: 0;
  }

  @media print {
    display: none;
  }
}

.menu {
  margin-top: -10px;
  height: 48px;
  position: relative;
  overflow: hidden;
}

.inner {
  max-width: 100%;
  padding: 0 calc(var(--geist-page-margin) - 12px);
  z-index: 1100;
  height: 48px;
  line-height: 48px;
  margin: auto auto -1px;
  display: flex;
  align-items: flex-end;
  line-height: normal;
  font-size: 16px;
  font-weight: 500;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
}

.inner::-webkit-scrollbar {
  display: none;
}

.item {
  position: relative;
  display: inline-block;
  padding: 16px 12px;
  color: var(--accents-5);
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  transition: color 0.2s ease;
  outline: none;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;

  &.active {
    color: var(--geist-foreground);

    &::before {
      content: "";
      display: block;
      position: absolute;
      height: 0;
      left: 9px;
      right: 9px;
      bottom: 0;
      border-bottom: 2px solid;
    }
  }
}
